<template>
  <div class="checkout">
    <div class="relative checkout-container">
      <div class="w-full max-w-rifyo mx-auto">
        <div class="relative z-10 checkout-cartrows">
          <div class="checkout-cartrowsitemleft max-xl:px-5">
            <div class="checkout-leftheader mb-10">
              <h1 class="checkout-leftheaderheading text-5xl font-bold">
                Checkout Repayment
              </h1>
            </div>
            <span class="checkout-headerproduct font-bold">Shipping Address</span>
            <input-box
              inputstyle="b"
              rootClassName="rootClassName5"
              text="Address"
              name="address"
              textinputPlaceholder="Address"
              v-model="address"
            ></input-box>
            <p class="red-text padding-down" v-if="!address && errorValidation">
              Address is required
            </p>
            <div class="checkout-rowselect">
              <div class="rootClassName2 relative">
                  <form autocomplete="off">
                    <span class="input-box-text">Kota/Kabupaten</span>
                    <Dropdown
                      ref="selectCity"
                      :maxItem="1000"
                      name="city"
                      :options="areaList"
                      placeholder="Kota/Kabupaten"
                      v-on:selected="selectedCity"
                      autocomplete="false"
                    >
                    </Dropdown>
                    <img
                      :src="`/playground_assets/chevron-down.svg`"
                      :alt="`image`"
                      class="input-select-arrow-down pointer-events-none"
                    />
                    <p
                      class="red-text padding-down"
                      v-if="!districtItem.city_id && errorValidation"
                    >
                      Kota is required
                    </p>
                  </form>
              </div>
              <div class="rootClassName2 relative">
                <form autocomplete="off">
                  <span class="input-box-text">Kecamatan</span>
                  <Dropdown
                    ref="selectDistrict"
                    :maxItem="1000"
                    name="kecamatan"
                    :options="districtList"
                    placeholder="Kecamatan"
                    v-on:selected="selectedDistrict"
                    autocomplete="false"
                  >
                  </Dropdown>
                  <img
                    :src="`/playground_assets/chevron-down.svg`"
                    :alt="`image`"
                    class="input-select-arrow-down pointer-events-none"
                  />
                  <p
                    class="red-text padding-down"
                    v-if="!districtItem.id && errorValidation"
                  >
                    Kecamatan is required
                  </p>
                </form>
              </div>
            </div>
            <div class="kode-pos">
              <input-box
                inputstyle="b"
                rootClassName="rootClassName7"
                text="Kode Pos"
                v-model="zipcode"
                type="number"
                textinputPlaceholder="15560"
              ></input-box>
              <p class="red-text padding-down" v-if="!zipcode && errorValidation">
                Kode Pos is required
              </p>
            </div>
            <div class="checkout-containercheck">
              <input type="checkbox" class="checkout-checkbox" />
              <span class="checkout-text"
                >Save this address for next purchase</span
              >
            </div>
            <div class="checkout-leftheader1" v-if="kurirs.length">
              <h1 class="checkout-leftheaderheading1 text-2xl font-bold">
                Shipping Method
              </h1>
            </div>
            <div class="loading mb-4" v-if="isGetShipping">
              <Loading />
            </div>
            <div class="checkout-shippinglist" v-if="!isGetShipping">
              <div
                :class="
                  selectedKurir.index === index
                    ? 'checkout-shippingboxactive'
                    : 'checkout-shippingbox'
                "
                v-for="(kr, index) in kurirs"
                :key="kr.id"
              >
                <input
                  type="radio"
                  @click="pilihKurir(kr, index)"
                  name="radio"
                  class="checkout-shipradio1 cursor-pointer"
                />
                <div class="checkout-shipdetail lg:w-1/2">
                  <span class="checkout-text1"
                    >{{ kr.code.toUpperCase() }} - {{ kr.service }}</span
                  >
                  <template v-if="kr.code.toUpperCase() === 'CUSTOM - RIFYO LOGISTIK'">
                    <span class="checkout-text2">({{ kr.cost[0].etd }})</span>
                  </template>
                  <template v-else>
                    <span class="checkout-text2">({{ kr.cost[0].etd }} Days)</span>
                  </template>
                </div>
                <span v-if="kr.cost[0].value != 0" class="checkout-shipprice lg:w-1/2 text-right">{{
                    formatIDR(kr.cost[0].value)
                  }}</span>
                  <span v-else class="checkout-shipprice">Gratis Biaya</span>
              </div>
            </div>
            <div class="checkout-leftheader2">
              <h1 class="checkout-leftheaderheading2 text-2xl font-bold">
                Apply Voucher
              </h1>
            </div>
            <div v-if="voucherMsg">
              <span :class="{
                'block red-text mb-2.5': !voucherOn,
                'block green-text mb-2.5 font-medium': voucherOn,
                }">{{ voucherMsg }}
              </span>
            </div>
            <div class="checkout-voucher-box">
              <input
                type="text"
                placeholder="input code voucher"
                autocomplete="on"
                class="mb-2 checkout-textinput input"
                @keyup="getVoucher($event.target.value)"
              />
              <img
                alt="image"
                src="/playground_assets/arrow-right-black.svg"
                class="checkout-arrow-down cursor-pointer"
              />
            </div>
          </div>
          <div class="checkout-cartrowsitemright max-xl:px-5">
            <div class="checkout-summary">
              <span class="checkout-headerproduct1 mb-4 font-bold"
                >Order Summary</span
              >
            </div>
            <div class="pb-3 w-full border-b border-gray-400 border-solid">
              <template v-if="carts.repayment">
                <div class="checkout-product-title py-4">
                  <span class="checkout-producttitle01">Product</span>
                </div>
                <div
                  v-for="item in carts.repayment"
                  :key="item.product_id + '__product'"
                  style="width: 100%"
                >
                  <div class="checkout-product-name py-2 mb-4">
                    <span class="checkout-producttitle02">
                      {{ item.title }}
                    </span>
                  </div>
                  <div class="grid grid-cols-2 gap-5 mb-4">
                    <div class="col-span-1" v-for="pv in item.product_variants" :key="pv.id">
                      <span class="block mb-2 checkout-producttitle02">{{ pv.title }}</span>
                      <span>{{ pv.name }}</span>
                    </div>
                  </div>
                  <div class="checkout-productsubtotal">
                    <div class="checkout-productprice">
                      <span class="checkout-subprice">{{
                        formatIDR(item.repayment)
                      }}</span>
                      <span class="checkout-subqty">x{{ item.qty }}</span>
                    </div>
                    <span class="checkout-subtotalvalue font-semibold">{{
                      formatIDR(item.repayment)
                    }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="pb-6 w-full border-b border-gray-400 border-solid"
              v-if="selectedKurir.code"
            >
              <div class="checkout-product-title06">
                <span class="checkout-producttitle07">Shipping</span>
              </div>
              <div class="checkout-shippingbox1">
                <div class="checkout-shipdetail2">
                  <span class="checkout-text5"
                    >{{ selectedKurir.code.toUpperCase() }} -
                    {{ selectedKurir.service }}</span
                  >
                  <template v-if="selectedKurir.code.toUpperCase() =='RIFYO LOGISTIK'">
                    <span class="checkout-text6">({{ selectedKurir.cost[0].etd }})</span>
                  </template>
                  <template v-else>
                    <span class="checkout-text6">({{ selectedKurir.cost[0].etd }} Days)</span>
                  </template>
                </div>
                <span v-if="selectedKurir.cost[0].value != 0" class="checkout-shipprice2">{{
                  formatIDR(selectedKurir.cost[0].value)
                }}</span>
                <span v-else class="checkout-shipprice2">Gratis Biaya</span>
              </div>
            </div>
            <div
              class="pb-6 w-full border-b border-gray-400 border-solid"
              v-if="voucher && voucher.name"
            >
              <div class="checkout-product-title08">
                <span class="checkout-producttitle09">Voucher</span>
              </div>
              <div class="checkout-voucherbox">
                <div class="checkout-shipdetail3">
                  <span class="checkout-text7">{{ voucher.name }}</span>
                </div>
                <span class="checkout-shipprice3">{{
                  `- ${formatIDR(voucherValue)}`
                }}</span>
              </div>
            </div>
            <div class="checkout-grosstotal1">
              <span class="checkout-grosstotallabel1">Total Order</span>
              <span class="checkout-grosstotalvalue1 font-semibold font-sans">{{
                formatIDR(totalOrder)
              }}</span>
            </div>
            <XClose v-if="isModalError" :text="error_msg" />
            <button
              :disabled="isProcessing"
              type="button"
              class="checkout-btn-login"
              @click="checkout"
            >
              <span class="checkout-text8">{{
                isProcessing ? 'Processing Payment...' : 'Payment'
              }}</span>
            </button>
          </div>
        </div>
        <ModalIframe v-if="isPay" :url="url" />
        <div class="bgelement absolute bg-[#eae3d9] h-full w-[35%] xl:w-[38%] 2xl:w-[41%] right-0 top-0">
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import InputBox from '../components/input-box';
  import InputSelect from '../components/input-select';
  import Footer from '../components/footer';
  import Dropdown from 'vue-simple-search-dropdown';
  import XClose from '../components/x-close';
  import ModalIframe from '../components/modal-iframe';
  import moment from 'moment';
  import Api from '../api';
  import { formatRupiah } from '../lib/index';
  import axios from 'axios';
  import Loading from '../components/Loading.vue';
  export default {
    name: 'CheckoutRepayment',
    data() {
      return {
        url: '',
        isPay: false,
        areaList: [],
        districtList: [],
        errorValidation: false,
        isProcessing: false,
        order_id: null,
        carts:[ {
          sub_total: 0,
          total: 0,
        }],
        cartCount: 0,
        cityId: null,
        districtItem: {
          city_id: null,
          id: null,
        },
        kurirs: [],
        selectedKurir: {
          index: null,
        },
        address: null,
        zipcode: null,
        error_msg: '',
        isModalError: false,
        voucher: {
          name: null,
        },
        voucherMsg: null,
        voucherOn: false,
        isGetShipping: false,
        shipping: null,
        dp: null,
      };
    },
    mounted() {
      let snap = document.createElement('script');
      snap.setAttribute('src', 'https://app.sandbox.midtrans.com/snap/snap.js');
      snap.setAttribute('data-client-key', process.env.VUE_APP_MIDTRANS_KEY);
      snap.setAttribute('async', true);
      snap.setAttribute('deffer', true);
      document.head.appendChild(snap);
    },

    computed: {
      downPayment() {
        return this.dp ? parseInt(this.dp.value) : 0;
      },

      subTotal() {
        let subtotal = 0;
        if (this.carts.length) {
          subtotal += this.carts.sub_total;
        }
        return subtotal;
      },

      totalOrder() {
        let total = 0;
        if (this.selectedKurir.code) {
          total = total + this.selectedKurir.cost[0].value;
        }
        if (this.carts) {
          total += this.carts.total;
        }
        if (this.voucherValue) {
          total -= this.voucherValue;
        }
        return total;
      },

      voucherValue() {
        if (this.voucher) {
          if (
            moment().isBefore(this.voucher.end) &&
            this.carts.total >= parseInt(this.voucher.minimum_order) &&
            this.voucher.quota
          ) {
            if (this.voucher.type == 'percent') {
              const discount = (this.carts.total * this.voucher.value) / 100;

              if (this.carts.total > this.voucher.value_maximum) {
                const cut = parseInt(this.voucher.value_maximum) || discount;
                return cut;
              } else {
                return discount;
              }
            } else {
              return parseInt(this.voucher.value);
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      },

      shippingMetric() {
        let product_id = null;
        let height = 0;
        let length = 0;
        let weight = 0;
        let width = 0;
        if (this.carts) {
          if (this.carts.repayment.length) {
            for (let index = 0; index < this.carts.repayment.length; index++) {
              product_id =
                this.carts.repayment[index].product_variants[0].product_id;
              height += this.carts.repayment[index].product_variants[0].height;
              length += this.carts.repayment[index].product_variants[0].length;
              weight += this.carts.repayment[index].product_variants[0].weight;
              width += this.carts.repayment[index].product_variants[0].width;
            }
          }
        }
        return {
          product_id,
          height,
          length,
          weight,
          width,
        };
      },
    },

    methods: {
      async getDP() {
        try {
          const response = await axios.get(`${Api.downPaymentUrl}`);
          if (response.data.status) {
            this.dp = response.data.data;
          }
        } catch (error) {
          throw new Error(error);
        }
      },
      async getShippingInfo() {
        const content = await axios.get(`${Api.profileShippingUrl}`);
        if (content.data.status) {
          this.shipping = content.data.data;
          this.address = content.data.data.address;
          this.cityId = content.data.data.city.id;
          this.districtItem.id = content.data.data.subdistrict.id;
          this.districtItem.city_id = content.data.data.city.id;
          this.zipcode = content.data.data.zipcode;
          this.$refs.selectCity.searchFilter = content.data.data.city.name;
          this.$refs.selectDistrict.searchFilter =
            content.data.data.subdistrict.name;
          this.getShipping(content.data.data.subdistrict.id);
        }
      },
      async getVoucher(vc) {
        this.voucherMsg = null;
        if (vc.length > 2) {
          try {
            const content = await axios.post(`${Api.voucherUrl}`, {
              code: vc,
              order_id: this.$route.params.id,
            });
            if (this.carts.total < parseInt(content.data.data.minimum_order)) {
              this.voucherMsg = 'Total minimum pemesanan belum terpenuhi';
            } else {
              this.voucherMsg = 'Voucher Berhasil Digunakan';
              this.voucher = content.data.data;
              this.voucherOn = true;
            }
          } catch (error) {
            this.voucher = null;
            this.voucherOn = false;
            this.voucherMsg = error.response.data.errors
              ? error.response.data.message
              : null;
          }
        }
      },
      async checkout() {
        try {
          this.isProcessing = true;
          if (
            !this.selectedKurir.code ||
            !this.address ||
            !this.districtItem.city_id ||
            !this.zipcode ||
            !this.districtItem.id
          ) {
            this.errorValidation = true;
            if (!this.selectedKurir.code) {
              this.error_msg = 'Shipping belum di pilih';
              this.isModalError = true;
            }
            this.isProcessing = false;
          } else {
            this.isModalError = false;
            this.errorValidation = false;
            await this.getCart();
            // checkout
            const content = await axios.post(`${Api.orderDetailRepaymentUrl}/${this.$route.params.id}`, {
              shipping_address: {
                address_id: this.districtItem.id,
                address: this.address,
                city_id: this.districtItem.city_id,
                subdistrict_id: this.districtItem.id,
                zipcode: this.zipcode,
                is_address: true,
              },
              shipping_method: {
                shipper: this.selectedKurir.code,
                shipping_cost: this.selectedKurir.cost[0].value,
                service: this.selectedKurir.service,
              },
              voucher: this.voucher,
              sub_total: this.subTotal,
              total: this.totalOrder,
            });

            if (content.data.status) {
              await this.payNow(content.data.data.midtrans.token);
            } else {
              this.error_msg = content.data.message;
              this.isModalError = true;
              this.isProcessing = false;
            }
          }
        } catch (e) {
          this.error_msg = e.message;
          this.isModalError = true;
          this.isProcessing = false;
        }
      },

      async payNow(token) {
        const _SELF = this
        await window.snap.pay(token);
      },

      pilihKurir(item, index) {
        this.selectedKurir = { ...item, index };
      },

      selectedDistrict(item) {
        this.districtItem = item;
        this.getShipping(item.id);
      },

      selectedCity(city) {
        this.cityId = city.id;
        this.getDistrict(this.cityId);
      },

      formatIDR(rp) {
        return 'IDR ' + formatRupiah(rp);
      },
      async getCart() {
        try {
          let fullUrl = Api.orderDetailRepayment + `/${this.$route.params.id}`;
          const response = await this.$http.get(fullUrl);
          this.carts = response.data.data;
          this.carts.total = response.data.data.total;
          this.carts.sub_total = response.data.data.sub_total;
        } catch (error) {
          throw error;
        }
      },
      async getDistrict(id) {
        const content = await axios.get(`${Api.subdistrictUrl}?city_id=${id}`);
        this.districtList = content.data.map((f) => {
          return { ...f, name: f.subdistrict_name };
        });
      },

      async getShipping(dest_id) {
        try {
          this.isGetShipping = true;
          const content = await axios.post(`${Api.shippingMethodRepaymentUrl}`, {
            destination_id: dest_id,
            city_id: this.cityId,
            product_id: this.shippingMetric.product_id,
            order_id: this.$route.params.id,
            height: this.shippingMetric.height,
            length: this.shippingMetric.length,
            weight: this.shippingMetric.weight,
            width: this.shippingMetric.width,
          });

          this.isGetShipping = false;

          const resJnt = content.data?.jnt ? content.data?.jnt : [];
          let arrKurir = [];
          for (let index = 0; index < resJnt.length; index++) {
            const element = resJnt[index].costs.map((t) => ({
              ...t,
              name: resJnt[index].name,
              code: resJnt[index].code,
            }));
            arrKurir = arrKurir.concat(element);
          }

          this.kurirs = arrKurir;
          const resSap = content.data?.sap ? content.data?.sap : [];
          for (let index = 0; index < resSap.length; index++) {
            const element = resSap[index].costs.map((t) => ({
              ...t,
              name: resSap[index].name,
              code: resSap[index].code,
            }));
            arrKurir = arrKurir.concat(element);
          }

          this.kurirs = arrKurir;
          if (content.data.rifyo_logistic) {
            const addNew = {
              code: content.data.rifyo_logistic.name,
              description: content.data.rifyo_logistic.name,
              service: '',
              name: content.data.rifyo_logistic.name,
              cost: [
                {
                  etd: content.data.rifyo_logistic.est_shipping,
                  note: '',
                  value: parseInt(content.data.rifyo_logistic.price),
                },
              ],
            };
            this.kurirs = [...this.kurirs, addNew];
          }
        } catch (e) {
          this.isGetShipping = false;
          this.error_msg = e.response.data.message;
          this.isModalError = true;
        }
      },

      async getArea() {
        const content = await axios.get(Api.areaUrl);
        this.areaList = content.data.map((t) => {
          return { ...t, name: t.type + ' ' + t.city_name };
        });
      },
    },

    async created() {
      await this.getCart();
      await this.getArea();
      await this.getShippingInfo();
      await this.getDP();
      this.$root.$on('onCloseError', () => {
        this.isModalError = !this.isModalError;
      });
      // this.$root.$on('onSukses', () => {
      //   this.isPay = !this.isPay;
      // });
    },

    components: {
      InputBox,
      InputSelect,
      Footer,
      Dropdown,
      XClose,
      ModalIframe,
      Loading,
    },
  };
</script>

<style>
  .checkout .dropdown .dropdown-input {
    border-radius: 0 !important;
  }
  .input-select-arrow-down {
    right: 10px;
    width: 24px;
    top: 45px;
    position: absolute;
    object-fit: cover;
  }
  .red-text {
    color: #ff2444;
    font-family: Montserrat;
    font-size: 16px;
  }
  .green-text {
    color: #008f18;
    font-family: Montserrat;
    font-size: 16px;
  }
  .padding-down {
    margin-top: -24px;
  }
  .input-box-text {
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .dropdown {
    margin-bottom: var(--dl-space-space-twounits) !important;
  }
  .checkout .dropdown .dropdown-input {
    background: #fff;
    cursor: pointer;
    width: 100% !important;
    max-width: 100% !important;
    /* height: 64px !important; */
    font-size: 16px !important;
    font-family: Montserrat !important;
    border: 1px solid #eae3d9 !important;
    padding: 10px 16px !important;
  }
  .checkout .dropdown .dropdown-content {
    width: 100% !important;
    max-width: 100% !important;
    max-height: auto !important;
    font-size: 16px;
    font-family: Montserrat;
  }
  .checkout .dropdown .dropdown-content .dropdown-item {
    width: 100% !important;
    max-width: 100% !important;
    max-height: 64px !important;
    height: 64px !important;
    font-size: 16px;
    font-family: Montserrat;
    padding: 27px !important;
    border-bottom: 1px solid #1f252c !important;
  }
  .dropdown .dropdown-input {
    background: #fff;
    cursor: pointer;
    width: 100% !important;
    max-width: 100% !important;
    height: 64px !important;
    font-size: 16px !important;
    font-family: Montserrat !important;
    border: 1px solid #1f252c !important;
  }
  .dropdown .dropdown-content {
    width: 100% !important;
    max-width: 100% !important;
    max-height: auto !important;
    font-size: 16px;
    font-family: Montserrat;
  }
  .dropdown .dropdown-content .dropdown-item {
    width: 100% !important;
    max-width: 100% !important;
    max-height: 64px !important;
    height: 64px !important;
    font-size: 16px;
    font-family: Montserrat;
    padding: 27px !important;
    border-bottom: 1px solid #1f252c !important;
  }
  .checkout-container {
    width: 100%;
    display: flex;
    overflow: auto;
    align-items: center;
    flex-direction: column;
  }
  .checkout-cartrows {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: flex-start; */
  }
  .checkout-cartrowsitemleft {
    flex: 0 0 auto;
    width: 65%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: 120px;
    padding-right: 40px;
    flex-direction: column;
  }
  .checkout-leftheader {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .checkout-leftheaderheading {
    color: #1f252c;
    font-family: Lora;
  }
  .checkout-headerproduct {
    color: #1f252c;
    font-size: 24px;
    font-style: normal;
    font-family: Lora;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .checkout-rowselect {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
  }
  .checkout-containercheck {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .checkout-checkbox {
    width: 24px;
    border: 2px solid #1f252c;
    height: 24px;
  }
  .checkout-text {
    color: #1f252c;
    font-family: Montserrat;
    margin-left: var(--dl-space-space-unit);
  }
  .checkout-leftheader1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .checkout-leftheaderheading1 {
    color: #1f252c;
    font-family: Lora;
  }
  .checkout-shippinglist {
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 10px;
  }
  .checkout-shippingboxactive {
    flex: 0 0 auto;
    width: 49%;
    border: 1px solid #1f252c;
    height: 100px;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
    background-color: #1f252c;
  }
  .checkout-shipradio {
    width: 24px;
    height: 24px;
  }
  .checkout-shipdetail {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .checkout-text1 {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .checkout-text2 {
    color: #1f252c;
    font-size: 14px;
    font-family: Montserrat;
  }
  .checkout-shipprice {
    color: #1f252c;
    margin-left: auto;
  }

  .checkout-shippingboxactive .checkout-shipprice {
    color: #fff;
  }
  .checkout-shippingboxactive .checkout-text2 {
    color: #fff;
  }
  .checkout-shippingboxactive .checkout-text1 {
    color: #fff;
  }

  .checkout-shippingbox {
    flex: 0 0 auto;
    width: 49%;
    border: 1px solid #eae3d9;
    min-height: 110px;
    display: flex;
    padding: var(--dl-space-space-unit);
    align-items: flex-start;
  }
  .checkout-shipradio1 {
    width: 24px;
    height: 24px;
    border: solid 2px #1f252c !important;
  }
  .checkout-shippingboxactive .checkout-shipradio1 {
    background-color: #1f252c !important;
    border: solid 2px white !important;
    box-shadow: unset !important;
    color: transparent;
  }
  .checkout-shipdetail1 {
    display: flex;
    align-items: flex-start;
    margin-left: var(--dl-space-space-unit);
    flex-direction: column;
  }
  .checkout-text3 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .checkout-text4 {
    font-size: 14px;
    font-family: Montserrat;
  }
  .checkout-shipprice1 {
    margin-left: auto;
  }
  .checkout-leftheader2 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-end;
  }
  .checkout-leftheaderheading2 {
    color: #1f252c;
    font-family: Lora;
    margin-bottom: var(--dl-space-space-unit);
  }
  .checkout-voucher-box {
    flex: 0 0 auto;
    width: 48%;
    display: flex;
    position: relative;
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .checkout-textinput {
    width: 100%;
    font-size: 16px;
    font-family: Montserrat;
    border: 1px solid #eae3d9 !important;
    padding: 10px 16px !important;
  }
  .checkout-arrow-down {
    right: 14px;
    width: 24px;
    top: 12px;
    position: absolute;
    object-fit: cover;
  }
  .checkout-cartrowsitemright {
    flex: 0 0 auto;
    width: 35%;
    /* height: 100%; */
    display: flex;
    align-items: flex-start;
    padding-top: 120px;
    padding-left: 40px;
    flex-direction: column;
  }
  .checkout-summary {
    width: 100%;
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #aea79d;
    flex-direction: column;
  }
  .checkout-headerproduct1 {
    color: #1f252c;
    font-size: 24px;
    font-style: normal;
    font-family: Lora;
    font-weight: 500;
  }
  .checkout-product-title {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-producttitle01 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-product-name {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .checkout-producttitle02 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-productsubtotal {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .checkout-productprice {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .checkout-subprice {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .checkout-subqty {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
    margin-left: var(--dl-space-space-unit);
  }
  .checkout-subtotalvalue {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .checkout-product-title03 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-producttitle04 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-product-name1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .checkout-producttitle05 {
    color: #1f252c;
    width: 50%;
    font-size: 16px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-productsubtotal1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-halfunit);
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .checkout-productprice1 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .checkout-subprice1 {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
  }
  .checkout-subqty1 {
    color: #1f252c;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 400;
    margin-left: var(--dl-space-space-unit);
  }
  .checkout-grosstotal {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    border-bottom: 1px solid #aea79d;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-grosstotallabel {
    color: #1f252c;
    width: 50%;
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .checkout-grosstotalvalue {
    color: #1f252c;
    font-size: 16px;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
  }
  .checkout-product-title06 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-producttitle07 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-shippingbox1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .checkout-shipdetail2 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .checkout-text5 {
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .checkout-text6 {
    font-size: 14px;
    font-family: Montserrat;
  }
  .checkout-shipprice2 {
    margin-left: auto;
  }
  .checkout-product-title08 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-producttitle09 {
    color: #1f252c;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-voucherbox {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .checkout-shipdetail3 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .checkout-text7 {
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: 0px;
  }
  .checkout-shipprice3 {
    color: #ff2444;
    font-style: normal;
    font-family: Montserrat;
    font-weight: 500;
    margin-left: auto;
  }
  .checkout-grosstotal1 {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    padding-top: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .checkout-grosstotallabel1 {
    color: #1f252c;
    font-size: 16px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-grosstotalvalue1 {
    color: #1f252c;
    font-size: 16px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
  }
  .checkout-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 44px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .checkout-text8 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  .rootClassName2 {
    width: 48%;
  }
  .kode-pos {
    width: 30%;
  }
  @media (max-width: 991px) {
    .bgelement {
      display: none;
    }
    .checkout-cartrows {
      height: auto;
      flex-wrap: wrap;
    }
    .checkout-cartrowsitemleft {
      width: 100%;
    }
    .checkout-rowselect {
      flex-wrap: wrap;
    }
    .checkout-shippinglist {
      flex-wrap: wrap;
    }
    .checkout-shippingboxactive {
      width: 100%;
      margin-bottom: var(--dl-space-space-unit);
    }
    .checkout-shippingbox {
      width: 100%;
      height: 100px;
    }
    .checkout-voucher-box {
      width: 100%;
    }
    .checkout-cartrowsitemright {
      width: 100%;
      padding: 40px 0;
      background-color: #eae3d9;
    }
    .rootClassName2 {
      width: 100%;
    }
    .kode-pos {
      width: 100%;
    }
  }

  @media (min-width: 2560px) {
    .bgelement {
      width: 44vw !important;
    }
  }
</style>
