<template>
  <div class="modal-password-container" @click.self="closeModal()">
    <div class="modal-password-modal-wrap">
       <iframe :src="url" width="100%" height="100%"></iframe>
       <div class="modal-password-btn-login"  @click="openLink()">
        <span class="modal-password-text2">Open New Tab</span>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from "./input-box";
export default {
  name: "ModalIframe",
   data() {
    return {
      loaded: false,
    }
  },
  mounted() {
		this.$root.$on("onSukses", () => {
      this.isSukses = !this.isSukses;
    });
	},
  props: {
    url: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: "Review Sent",
    },
    image_src1: {
      type: String,
      default: "/playground_assets/x_close.svg",
    },
    image_alt1: {
      type: String,
      default: "image",
    },
    desc: {
      type: String,
      default:
        "Your review has been sent and will be moderated by us before it appears on the product page.",
    },
    text: {
      type: String,
      default: "Ok",
    },
    onClose: { type: Function },
  },
  components: {
    InputBox,
  },
  methods: {
    openLink(){
      window.location.assign(
        this.url,
        '_blank' // <- This is what makes it open in a new window.
      );
    },
     closeModal() {
      this.$root.$emit("onSukses");
    },
  }
};
</script>

<style scoped>
.row-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.modal-password-container {
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-password-modal-wrap {
  flex: 0 0 auto;
  width: 500px;
  height: 90%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #fff;
  z-index: 101;
}
.modal-password-modal-header {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.modal-password-text {
  font-size: 2em;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
}
.modal-password-image {
  width: 46px;
  object-fit: cover;
}
.modal-password-text1 {
  font-family: Montserrat;
  line-height: 2;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.modal-password-btn-login {
  flex: 0 0 auto;
  width: 100%;
  cursor: pointer;
  height: 64px;
  display: flex;
  position: relative;
  /* margin-top: var(--dl-space-space-unit); */
  align-items: center;
  justify-content: flex-start;
  background-color: #1f252c;
}
.modal-password-text2 {
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-style: normal;
  text-align: center;
  font-family: Montserrat;
  font-weight: 700;
  letter-spacing: 2.4px;
}
@media (max-width: 991px) {
  .modal-password-modal-wrap {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 479px) {
  .modal-password-modal-wrap {
    width: 100%;
  }
}
</style>
